import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import gateway from '../../../utils/gateway';
import { showError } from '../../../utils/toast';
import organizationRelationship from '../../../constants/organizationRelationship';

dayjs.extend(utc);
dayjs.extend(timezone);

const COLUMNS = [
  {
    id: 'product',
    label: 'PRODUTO',
  },
  { id: 'type', label: 'Tipo.' },
  { id: 'quantity', label: 'QTD.' },
  { id: 'price', label: 'PREÇO' },
  { id: 'discount', label: 'DESCONTO' },
  { id: 'total', align: 'right', label: 'TOTAL' },
  { id: 'remove', align: 'center', label: '' },
  { id: 'edit', align: 'center', label: '' },
];

const TYPE = {
  0: { label: 'SAAS' },
  1: { label: 'Serviços' },
  2: { label: 'Outros' },
};

const ItemsTableCell = withStyles(() => ({
  root: {
    padding: 8,
    border: 0,
    '&:first-child': {
      padding: '8px 16px 8px 0',
    },
    '&:last-child': {
      padding: '8px 0 8px 16px',
    },
  },
}))(TableCell);

const useStyles = makeStyles(() => ({
  image: {
    borderRadius: 16,
    width: 52,
    height: 52,
  },
  cancel: {
    textTransform: 'none',
  },
  confirm: {
    fontWeight: 700,
    textTransform: 'none',
  },
}));

const ReviewStep = ({
  show,
  step,
  total,
  onPreviousStep,
  context,
  label,
  first,
  spot,
  amendment,
  contract,
  update,
  addSubscription,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);

    const proposal_items = context.proposal_items.map((item) => {
      let formattedObservations;
      if (item.observations === null || item.observations === '') {
        formattedObservations = '';
      } else {
        const editor = convertFromRaw(JSON.parse(item.observations));
        formattedObservations = editor.hasText() ? stateToHTML(editor) : '';
      }
      return {
        ...item,
        observations: formattedObservations,
      };
    });

    const payload = {
      ...context,
      organization_member_type: null,
      contract_type: addSubscription ? 0 : context.contract_type,
      proposal_items,
      observations: context.formattedObservations,
    };

    if (context.contract_type === 6) {
      payload.usage_start = moment(context.usage_start).format(
        'YYYY-MM-DDTHH:mm:ssZ',
      );
      payload.usage_end = moment(context.usage_end).format(
        'YYYY-MM-DDTHH:mm:ssZ',
      );
    } else {
      payload.usage_start = null;
      payload.usage_end = null;
    }

    if (context.contract_type !== 5) {
      payload.percentage = null;
    }

    if (payload.tenant) {
      payload.tenant_id = payload.tenant.id.toString();
    } else {
      payload.tenant_id = null;
    }
    if (payload.subscription) {
      payload.contract_id = payload.subscription.id.toString();
    } else {
      payload.contract_id = null;
    }
    payload.deal_key = payload.deal ? payload.deal.value.toString() : null;
    if (payload.contract_type === 2 && payload.tenant_owner) {
      payload.tenant_owner_id = payload.tenant_owner.id.toString();
    } else {
      payload.tenant_owner_id = null;
    }
    payload.proposal_items = payload.proposal_items.map((item) => {
      return {
        ...item,
      };
    });

    if (payload.organization_relationship === organizationRelationship.child) {
      payload.organization_member_type = 'child';
      payload.organization_id = payload.organization_id?.value || null;
      payload.organization_name = null;
      payload.organization_type = null;
      payload.billing_owner = null;
    } else {
      payload.organization_member_type = 'parent';
      payload.organization_type =
        payload.organization_relationship === organizationRelationship.business
          ? 'business'
          : 'franchise';
      if (payload.billing_owner === 'self') {
        payload.organization_name = null;
        payload.organization_id = null;
        payload.organization_member_id = null;
      } else if (update) {
        payload.organization_id = payload.organization_id?.value || null;
      }
    }

    payload.proposal_items = payload.proposal_items.map((item) => {
      return {
        ...item,
        contract_product_id:
          item.contract_product.id || item.contract_product.value,
      };
    });

    let response;

    try {
      if (update) {
        response = await gateway.post(
          `/billing/proposals/${context.id}`,
          payload,
        );
        history.push(`/proposals/${response.data.proposal.id}`);
        return;
      }

      if (addSubscription) {
        response = await gateway.post(
          `/billing/proposals/subscription`,
          payload,
        );
      }

      if (spot) {
        response = await gateway.post(`/billing/proposals/spot`, payload);
      }
      if (amendment) {
        response = await gateway.post(`/billing/proposals/amendment`, payload);
      }
      if (first) {
        response = await gateway.post(`/billing/proposals/first`, payload);
      }
      if (contract) {
        response = await gateway.post(`/billing/proposals/contract`, payload);
      }

      history.push(`/proposals/${response.data.proposal.id}`);
    } catch (err) {
      console.error(err);
      showError(err?.response?.data?.message);
      setSubmitting(false);
    }
  };

  if (!show) {
    return null;
  }

  const totalRecurrent = context.proposal_items.reduce((sum, item) => {
    if (
      item.contract_product.recurrency_type === 1 ||
      item.contract_product.recurrency_type === 2
    ) {
      return sum + item.total;
    }
    return sum;
  }, 0);

  const formattedTotalRecurrent = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalRecurrent);

  const totalSpot = context.proposal_items.reduce((sum, item) => {
    if (
      item.contract_product.recurrency_type === 0 ||
      item.contract_product.recurrency_type === 3
    ) {
      return sum + item.total;
    }
    return sum;
  }, 0);

  const formattedTotalSpot = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(totalSpot);

  const items = [...context.proposal_items];
  const remove = [];

  if (amendment) {
    for (let i = 0; i < context.contract_items.length; i += 1) {
      remove.push(true);
    }

    for (let i = 0; i < items.length; i += 1) {
      const original = context.contract_items.find(
        (item) => item.contract_product.id === items[i].contract_product.id,
      );
      if (original) {
        remove[i] = false;
        if (
          original.quantity !== items[i].quantity ||
          original.price !== items[i].price ||
          original.discount !== items[i].discount ||
          original.discount_type !== items[i].discount_type
        ) {
          items[i].original = original;
        }
      }
    }
    for (let i = 0; i < context.contract_items.length; i += 1) {
      if (remove[i]) {
        items.push({
          ...context.contract_items[i],
          remove: true,
        });
      }
    }
  }

  return (
    <Fade in>
      <Paper square elevation={2} style={{ flex: 1 }}>
        <Box p={0} display="flex" flexDirection="column">
          <Box p={4} pt={6} display="flex" flexDirection="column">
            <Typography variant="subtitle2" color="primary">
              Passo {step + 1} de {total}
            </Typography>
            <Typography variant="h4">{label || 'Revisão'}</Typography>
          </Box>
          <Box p={4} pt={4} pb={8} display="flex" flexDirection="column">
            <Box mb={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Cliente vinculado</Typography>
                <Typography variant="body1">
                  {context.tenant ? context.tenant.name : '-'}
                </Typography>
              </Box>
              {context.tenant === null && (
                <Box mt={1}>
                  <Alert severity="info">
                    Um novo cliente será criado quando a proposta for
                    confirmada.
                  </Alert>
                </Box>
              )}
            </Box>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Contrato vinculado</Typography>
              <Typography variant="body1">
                {context.contract
                  ? `#${context.contract.id} - ${
                      context.tenant.name
                    } (de ${moment(context.contract.starts_at).format(
                      'DD/MM/YYYY',
                    )} até ${moment(context.contract.ends_at).format(
                      'DD/MM/YYYY',
                    )})`
                  : '-'}
              </Typography>
            </Box>
            <Box mb={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Negócio vinculado</Typography>
                <Typography variant="body1">
                  {context.deal ? context.deal.label : '-'}
                </Typography>
              </Box>
              {context.deal === null && (
                <Box mt={1}>
                  <Alert severity="info">
                    Um novo negócio no Pipedrive será criado ao salvar a
                    proposta.
                  </Alert>
                </Box>
              )}
            </Box>

            {context.tenant_owner !== null &&
              context.tenant_owner !== undefined && (
                <>
                  <Box
                    mb={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h6">Empresa Franqueadora</Typography>
                    <Typography variant="body1">
                      {context.tenant_owner.name}
                    </Typography>
                  </Box>
                </>
              )}
            <Box
              mb={4}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Identificação da proposta</Typography>
              <Typography variant="body1">{context.name}</Typography>
            </Box>
            <Box mb={2} display="flex" flexDirection="column">
              <Typography variant="h6">Itens recorrentes</Typography>
              <TableContainer>
                <Table
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <TableHead>
                    <TableRow>
                      {COLUMNS.map((column) => (
                        <ItemsTableCell
                          key={column.id}
                          align={column.align ? column.align : 'left'}
                        >
                          <Typography variant="subtitle2" color="primary">
                            {column.label}
                          </Typography>
                        </ItemsTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items
                      .filter(
                        (item) =>
                          item.contract_product.recurrency_type === 1 ||
                          item.contract_product.recurrency_type === 2,
                      )
                      .map((item) => {
                        const formattedDiscount = Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item.discount);

                        const formattedPrice = Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item.price);

                        const formattedTotal = Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item.total);

                        let measure = '';
                        if (item.contract_product.measure === 1) {
                          measure = ' / mês';
                        } else if (item.contract_product.measure === 2) {
                          measure = ' / hora';
                        } else if (item.contract_product.measure === 3) {
                          measure = ' / usuário';
                        } else if (item.contract_product.measure === 4) {
                          measure = ' / usuário / mês';
                        } else if (item.contract_product.measure === 5) {
                          measure = ' / ano';
                        } else if (item.contract_product.measure === 6) {
                          measure = ' / usuário / ano';
                        }

                        let recurrency = '';
                        if (item.contract_product.recurrency_type === 1) {
                          recurrency = ' / mês';
                        } else if (
                          item.contract_product.recurrency_type === 2
                        ) {
                          recurrency = ' / ano';
                        }

                        const updatedQuantity =
                          item.original &&
                          item.original.quantity !== item.quantity;
                        const updatedPrice =
                          item.original && item.original.price !== item.price;
                        const updatedDiscount =
                          item.original &&
                          (item.original.discount_type !== item.discount_type ||
                            item.original.discount !== item.discount);
                        const updatedTotal =
                          item.original && item.original.total !== item.total;

                        return (
                          <TableRow
                            tabIndex={-1}
                            key={item.id}
                            style={{ opacity: item.remove ? 0.25 : 1 }}
                          >
                            <ItemsTableCell>
                              <Box display="flex" alignItems="center">
                                <Typography>
                                  {item.contract_product.name}
                                </Typography>
                              </Box>
                            </ItemsTableCell>
                            <ItemsTableCell>
                              <Box display="flex" alignItems="left">
                                <Typography>
                                  {TYPE[item.contract_product.type].label}
                                </Typography>
                              </Box>
                            </ItemsTableCell>
                            <ItemsTableCell align="left">
                              {updatedQuantity && (
                                <Typography
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  {item.original.quantity}
                                </Typography>
                              )}
                              <Typography>{item.quantity}</Typography>
                            </ItemsTableCell>
                            <ItemsTableCell align="left">
                              {updatedPrice && (
                                <Typography
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.original.price) + measure}
                                </Typography>
                              )}
                              <Typography>
                                {formattedPrice + measure}
                              </Typography>
                            </ItemsTableCell>
                            <ItemsTableCell align="left">
                              {updatedDiscount &&
                                item.original.discount_type === 0 && (
                                  <Typography
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {item.original.discount}%
                                  </Typography>
                                )}
                              {updatedDiscount &&
                                item.original.discount_type === 1 && (
                                  <Typography
                                    style={{ textDecoration: 'line-through' }}
                                  >
                                    {item.original.discount}
                                  </Typography>
                                )}
                              {item.discount_type === 0 && (
                                <Typography>{item.discount}%</Typography>
                              )}
                              {item.discount_type === 1 && (
                                <Typography>{formattedDiscount}</Typography>
                              )}
                            </ItemsTableCell>
                            <ItemsTableCell align="right">
                              {updatedTotal && (
                                <Typography
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  {Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  }).format(item.original.total) + recurrency}
                                </Typography>
                              )}
                              <Typography>
                                {formattedTotal + recurrency}
                              </Typography>
                            </ItemsTableCell>
                          </TableRow>
                        );
                      })}
                    {items.filter(
                      (item) =>
                        item.contract_product.recurrency_type === 1 ||
                        item.contract_product.recurrency_type === 2,
                    ).length === 0 && (
                      <TableRow>
                        <ItemsTableCell colSpan={COLUMNS.length} align="center">
                          <Box p={6}>
                            <Typography>
                              Nenhum item recorrente selecionado
                            </Typography>
                          </Box>
                        </ItemsTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box display="flex" flexDirection="row-reverse" p={4}>
                <Typography variant="subtitle1">
                  Total {`${formattedTotalRecurrent}`}
                </Typography>
              </Box>
            </Box>
            {addSubscription ? null : (
              <>
                <Typography variant="h6">Itens Spot</Typography>
                <TableContainer>
                  <Table
                    aria-labelledby="tableTitle"
                    size="medium"
                    aria-label="enhanced table"
                  >
                    <TableHead>
                      <TableRow>
                        {COLUMNS.map((column) => (
                          <ItemsTableCell
                            key={column.id}
                            align={column.align ? column.align : 'left'}
                          >
                            <Typography variant="subtitle2" color="primary">
                              {column.label}
                            </Typography>
                          </ItemsTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items
                        .filter(
                          (item) =>
                            item.contract_product.recurrency_type === 0 ||
                            item.contract_product.recurrency_type === 3,
                        )
                        .map((item) => {
                          const formattedDiscount = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.discount);

                          const formattedPrice = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.price);

                          const formattedTotal = Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }).format(item.total);

                          let measure = '';
                          if (item.contract_product.measure === 1) {
                            measure = ' / mês';
                          } else if (item.contract_product.measure === 2) {
                            measure = ' / hora';
                          } else if (item.contract_product.measure === 3) {
                            measure = ' / usuário';
                          } else if (item.contract_product.measure === 4) {
                            measure = ' / usuário / mês';
                          } else if (item.contract_product.measure === 5) {
                            measure = ' / ano';
                          } else if (item.contract_product.measure === 6) {
                            measure = ' / usuário / ano';
                          }

                          let recurrency = '';
                          if (item.contract_product.recurrency_type === 1) {
                            recurrency = ' / mês';
                          } else if (
                            item.contract_product.recurrency_type === 2
                          ) {
                            recurrency = ' / ano';
                          }

                          return (
                            <TableRow tabIndex={-1} key={item.id}>
                              <ItemsTableCell>
                                <Box display="flex" alignItems="center">
                                  <Typography>
                                    {item.contract_product.name}
                                  </Typography>
                                </Box>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Box display="flex" alignItems="left">
                                  <Typography>
                                    {TYPE[item.contract_product.type].label}
                                  </Typography>
                                </Box>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Typography>{item.quantity}</Typography>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                <Typography>
                                  {formattedPrice + measure}
                                </Typography>
                              </ItemsTableCell>
                              <ItemsTableCell>
                                {item.discount > 0 &&
                                  item.discount_type === 0 && (
                                    <Typography>{item.discount}%</Typography>
                                  )}
                                {item.discount > 0 &&
                                  item.discount_type === 1 && (
                                    <Typography>{formattedDiscount}</Typography>
                                  )}
                              </ItemsTableCell>
                              <ItemsTableCell align="right">
                                <Typography>
                                  {formattedTotal + recurrency}
                                </Typography>
                              </ItemsTableCell>
                            </TableRow>
                          );
                        })}
                      {items.filter(
                        (item) =>
                          item.contract_product.recurrency_type === 0 ||
                          item.contract_product.recurrency_type === 3,
                      ).length === 0 && (
                        <TableRow>
                          <ItemsTableCell
                            colSpan={COLUMNS.length}
                            align="center"
                          >
                            <Box p={6}>
                              <Typography>
                                Nenhum item spot selecionado
                              </Typography>
                            </Box>
                          </ItemsTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display="flex" flexDirection="row-reverse" p={4}>
                  <Typography variant="subtitle1">
                    Total {`${formattedTotalSpot}`}
                  </Typography>
                </Box>
              </>
            )}

            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">
                Data de expiração da proposta
              </Typography>
              <Typography variant="body1">
                {dayjs
                  .utc(context.expires_at)
                  .tz('America/Sao_Paulo')
                  .format('DD/MM/YYYY - HH:mm')}
              </Typography>
            </Box>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Observações</Typography>
            </Box>
            {context.formattedObservations && (
              <Box
                mb={4}
                p={1}
                pl={4}
                pr={4}
                bgcolor={fade(theme.palette.text.primary, 0.1)}
                dangerouslySetInnerHTML={{
                  __html: context.formattedObservations,
                }}
              />
            )}
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">E-mail para envio</Typography>
              <Typography variant="body1">
                {context.email ? context.email : '-'}
              </Typography>
            </Box>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Telefone para envio</Typography>
              <Typography variant="body1">
                {context.phone ? context.phone : '-'}
              </Typography>
            </Box>
          </Box>
          <Box
            p={2}
            pl={4}
            pr={4}
            display="flex"
            justifyContent="space-between"
            borderTop="1px solid #ededed"
          >
            <Button
              color="default"
              onClick={() =>
                onPreviousStep({
                  ...context,
                })
              }
              className={classes.cancel}
              startIcon={<ChevronLeftIcon />}
            >
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={submitting}
              className={classes.confirm}
              endIcon={
                submitting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CheckIcon />
                )
              }
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Fade>
  );
};

export default ReviewStep;
